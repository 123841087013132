import React from 'react'
import dynamic from 'next/dynamic'

const Application = dynamic(() => import('../app'), {ssr: false});

export default function App() {
    return (
        <div suppressHydrationWarning>
            {typeof window === 'undefined' ? null : <Application/>}
        </div>
    )
}